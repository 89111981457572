<template>
    <div class="card-header border-bottom pt-1 pb-1 header_txt">
        <h4 class="card-title">Payment Details</h4>
    </div>
    <div class="card-body mt-2">
      <form class="invoice-repeater" @submit.prevent="$emit('storeContact', {'items':payments})">

            <div class="row">
                <div class="col-md-6 offset-md-3 col-12">
                    <div  v-for="(formData, index) in payments" :key="index" class="row">

                        <div class="col-12">
                            <div class="mb-1 row">
                                <input type="text" id="account_name" class="form-control" v-model="formData.payment_account_name"
                                    placeholder="Banck account name" />
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="mb-1 row">
                                <input type="text" id="account_no" class="form-control" v-model="formData.account_no"
                                    placeholder="Bank account no." />
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="mb-1 row">
                                <input type="text" id="bank_name" class="form-control" v-model="formData.bank_name"
                                    placeholder="Bank name" />
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="mb-1 row">
                                <input type="text" id="branch_name" class="form-control" v-model="formData.branch_name"
                                    placeholder="Branch name" />
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="mb-1 row">
                                <input type="text" id="routing_no" class="form-control" v-model="formData.routing_no"
                                    placeholder="Routing no." />
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="mb-1 row">
                                <select class="form-control" id="gender" v-model="formData.credit_term">
                                    <option value="">Select Term</option>
                                    <option v-for="term in terms" :key="term" :value="term">{{term}}</option>
                                </select>
                            </div>
                      </div>

                        <div class="col-sm-6 offset-sm-3">
                            <button type="submit" class="btn btn-primary me-1">Submit</button>
                            <button type="reset" class="btn btn-outline-secondary">Reset</button>
                        </div>

                    </div>
                </div>
            </div>

        </form>
    </div>
</template>

<script setup>

import {defineProps, ref, watch} from "vue";
const props = defineProps({
  items: {
    type: Array
  }
})
const payments = ref([
  {
    payment_account_name: '',
    account_no: '',
    bank_name: '',
    branch_name: '',
    routing_no: '',
    credit_term: ''
  }
])

const terms = ref([
    'Due on receipt',
    'Net 7 days',
    'Net 10 days',
    'Net 15 days',
    'Net 20 days',
    'Net 30 days',
    'Net 45 days',
    'Net 60 Days',
    'Net 90 Days',
])

watch(() => props.items, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
  if (props.items.length) {
    payments.value = props.items
  }
}
</script>

<style scoped></style>
