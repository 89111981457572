<template>
  <div class="card-header border-bottom pt-1 pb-1">
    <h4 class="card-title">Professional reference</h4>
  </div>

  <div class="card-body mt-2">
    <form class="invoice-repeater" @submit.prevent="$emit('storeContact', {'items':references})">
      <div>

        <div v-for="(reference, index) in references" :key="index" class="row">
          <div class="col-md-4 col-12">
            <div class="mb-1">
              <input v-model="reference.professional_reference" type="text" class="form-control" id="reference"
                     placeholder="Reference"/>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="mb-1">
              <input v-model="reference.details" type="text" class="form-control" id="reference"
                     placeholder="Reference details"/>
            </div>
          </div>
          <div class="col-md-2 col-12 p_left_0">

            <div class="mb-1 _skill_out_visibility">

              <button v-if="references.length === (index + 1)" @click="addMore"
                      class="btn btn-outline-primary text-nowrap px-1 button_icon" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                     stroke-linejoin="round" class="feather feather-plus" style="width: 22px; height: 30px;">
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              </button>

              <button v-else @click="remove(index)"
                      class="btn btn-outline-danger text-nowrap px-1 button_icon" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                     stroke-linejoin="round" class="feather feather-trash-2"
                     style="width: 22px; height: 30px;">
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                  </path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </button>

            </div>

            <div class="mb-1 d-grid gap-2 d-none _skill_btn_visibility">

              <button v-if="references.length === (index + 1)" @click="addMore"
                      class="btn btn-primary text-nowrap px-1 button_icon" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                     stroke-linejoin="round" class="feather feather-plus" style="width: 22px; height: 30px;">
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              </button>

              <button v-else @click="remove(index)"
                      class="btn btn-danger text-nowrap px-1 button_icon" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                     stroke-linejoin="round" class="feather feather-trash-2"
                     style="width: 22px; height: 30px;">
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                  </path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </button>

            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-sm-12">
            <button type="submit" class="btn btn-primary me-1">Submit</button>
            <button @click="reset" type="reset" class="btn btn-outline-secondary">Reset</button>
          </div>
        </div>

      </div>

    </form>
  </div>
</template>

<script setup>

import {defineProps, ref, watch} from "vue";

const props = defineProps({
  items: {
    type: Array
  }
})

const references = ref([
  {
    professional_reference: '',
    details: ''
  }
])
const addMore = () => {
  references.value.push({
    professional_reference: '',
    details: ''
  })
}
const remove = index => {
  references.value.splice(index, 1)
}
const reset = () => {
  references.value.length = 0;
  addMore();
}

watch(() => props.items, (newFormData) => {
  formDataSet();
});
const formDataSet = () => {
  if (props.items.length){
    references.value = props.items
  }
}

</script>

<style scoped></style>
