<template>
  <div class="card-header border-bottom pt-1 pb-1">
    <h4 class="card-title">Fees</h4>
  </div>

  <div class="card-body mt-2">
    <form class="invoice-repeater" @submit.prevent="$emit('storeContact', {'items':fees})">
      <div class="row d-flex align-items-end">

        <div v-for="(fee, index) in fees" :key="index" class="col-md-5 col-5 card_body box_shadow">
          <div class="row d-flex align-items-end">
            <div class="col-md-12 col-12">
              <div class="mb-1">
                <input v-model="fee.contact_person_fees" type="text" class="form-control" id="contact_person_fees"
                       placeholder="Fees"/>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="row mt-2 d-flex align-items-start">
        <div class="col-sm-12">
          <button type="submit" class="btn btn-primary me-1">Submit</button>
          <button @click="reset" type="reset" class="btn btn-outline-secondary">Reset</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import {defineProps, ref, watch} from "vue";

const props = defineProps({
  items: {
    type: Array
  }
})

const fees = ref([
  {
    contact_person_fees: ''
  }
])

const addMore = () => {
  fees.value.push({
    contact_person_fees: ''
  })
}

const reset = () => {
  fees.value.length = 0;
  addMore();
}

watch(() => props.items, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
  if (props.items.length) {
    fees.value = props.items
  }
}

</script>
