<template>
  <div class="card-header border-bottom pt-1 pb-1">
    <h4 class="card-title">Education</h4>
    <div>
      <button @click="addMore" type="button" class="btn btn-primary me-1">+ Add new</button>
    </div>
  </div>

  <div class="card-body mt-2">
    <form class="invoice-repeater" @submit.prevent="$emit('storeContact', {'items':educations})">
    <div class="row d-flex align-items-end">

      <div v-for="(education, index) in educations" :key="index" class="col-md-5 col-5 card_body box_shadow">
        <div class="row d-flex align-items-end">

          <div class="col-12 org_icon_col">
            <div v-if="educations.length > 1" @click="remove(index)" class="org_dlt_icon">
              <button class="btn btn-outline-danger text-nowrap px-1 button_icon" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-trash-2" style="width: 22px; height: 30px;">
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </button>
            </div>
          </div>

          <div class="col-md-12 col-12">
            <div class="mb-1">
              <input v-model="education.education" type="text" class="form-control" id="institute"
                placeholder="School/university name. Ex: Dhaka University" />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="mb-1">
              <input v-model="education.degree" type="text" class="form-control" id="degree"
                placeholder="Degree. Ex: BSC in Computer Science" />
            </div>
          </div>

          <div class="col-md-12 col-12">
            <div class="row mb-1">

              <div class="col-md-6 col-12">
                <label class="form-label">Start Date</label>
                <input v-model="education.start_date" type="date" class="form-control" />
              </div>

              <div class="col-md-6 col-12">
                <label class="form-label">End Date</label>
                <input v-model="education.end_date" type="date" class="form-control"/>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="row mt-5 d-flex align-items-end">
      <div class="col-sm-4 offset-4">
        <button type="submit" class="btn btn-primary me-1">Submit</button>
        <button @click="reset" type="reset" class="btn btn-outline-secondary">Reset</button>
      </div>
    </div>
    </form>
  </div>
</template>

<script setup>
import {defineProps, ref, watch} from "vue";
const props = defineProps({
  items: {
    type: Array
  }
})

const educations = ref([
  {
    education: '',
    degree: '',
    start_date: null,
    end_date: null
  }
])

const addMore = () => {
  educations.value.push({
    education: '',
    degree: '',
    start_date: null,
    end_date: null
  })
}

const remove = index => {
  educations.value.splice(index, 1)
}

const reset = () => {
  educations.value.length = 0;
  addMore();
}

watch(() => props.items, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
  if (props.items.length) {
    educations.value = props.items
  }
}
</script>

<style scoped></style>
