<template>
  <div class="card-header border-bottom pt-1 pb-1 header_txt">
    <h4 class="card-title">Basic Info</h4>
  </div>
  <div class="card-body mt-2">
    <form class="form form-horizontal" @submit.prevent="$emit('storeContact', formData)">

      <div class="row">
        <div class="col-md-6 offset-md-3 col-12">
          <div class="row">

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="full_name" class="form-control" v-model="formData.full_name"
                       placeholder="Full name"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="legal_name" class="form-control" v-model="formData.legal_name"
                       placeholder="Legal name"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="location" class="form-control" v-model="formData.location"
                       placeholder="Location"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="bin_no" class="form-control" v-model="formData.bin_no"
                       placeholder="Bin No."/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="tin_no" class="form-control" v-model="formData.tin_no"
                       placeholder="Tin No."/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="reg.no" class="form-control" v-model="formData.reg_no"
                       placeholder="Registration No."/>
              </div>
            </div>

            <div class="col-12">
              <table class="table w-100">
                <thead>
                <tr>
                  <th>Role</th>
                  <th>Serial No</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(companyRole, index) in formData.company_roles_data">
                  <td class="p-1 w-50">
                    <v-select
                        @option:selected="getRoleChange"
                        placeholder="Select Role"
                        v-model="companyRole.id"
                        :options="companyRules"
                        label="name"
                        :reduce="name => name.id"
                    />
                  </td>
                  <td class="p-1 w-25"><input type="text" class="form-control" v-model="companyRole.serial_no"/></td>
                  <td class="d-flex p-1">
                    <select v-model="companyRole.status" class="form-control">
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                    <button v-if="companyRole.is_new" @click="removeRole(index)" type="button" class="float-end btn btn-close">
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
              <button @click="addMoreRole" type="button" class="btn btn-dropbox btn-sm ms-2"><i
                  class="fa fa-plus-square"></i> Add more
              </button>
            </div>

            <div class="col-sm-6 offset-sm-3">
              <button type="submit" class="btn btn-primary me-1">Submit</button>
              <button type="reset" class="btn btn-outline-secondary">Reset</button>
            </div>

          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script setup>
import {onMounted, ref, watch, defineProps} from "vue";

const props = defineProps({
  companyRules: {
    type: Array,
    default: []
  },
  formData: {
    type: Object
  }
})

const formData = ref({
  full_name: '',
  legal_name: '',
  location: '',
  bin_no: '',
  tin_no: '',
  reg_no: '',
  company_rules: [],
  company_roles_data: []
})

const addMoreRole = () => {
  formData.value.company_roles_data.push({
    is_new: true,
    id: '',
    label: '',
    serial_no: '',
    status: 'active',
  })
}

const removeRole = (index) => {
  formData.value.company_roles_data.splice(index, 1)
}
watch(() => props.formData, (newFormData) => {
  formDataSet();
});

const getRoleChange = () => {
  formData.value.company_rules = formData.value.company_roles_data.map(role => parseInt(role.id));
}

const formDataSet = () => {
  if (props.formData?.full_name) {
    formData.value = props.formData
    formData.value.company_rules = props.formData.company_roles_data.map(role => parseInt(role.id));
  }
}

onMounted(() => {
  formDataSet()
})
</script>
